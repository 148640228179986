/**
 * Created by osirvent on 16/02/2017.
 */
angular
    .module('annexaApp')
    .controller('SecController', ['$scope', '$rootScope', 'HeaderService', '$state', function ($scope, $rootScope, HeaderService, $state) {
        HeaderService.onChangeState($scope, function(message) {
        	if(_.contains(['annexa.sec.sessions', 'annexa.sec.proposals', 'annexa.sec.decrees'], message.state.name)) {
	            $rootScope.subHeadTabs = [
	                {
	                    state: 'annexa.sec.proposals',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.proposals' ? 'active' : ''),
	                    name: 'global.sec.proposals.title',
	                    permissions: ['view_proposals']
	                },
	                {
	                    state: 'annexa.sec.sessions',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.sessions' ? 'active' : ''),
	                    name: 'global.sec.literals.sessions',
	                    permissions: ['view_sessions']
	                },
	                {
	                    state: 'annexa.sec.decrees',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.decrees' ? 'active' : ''),
	                    name: 'global.sec.literals.decrees',
	                    permissions: ['view_decrees']
	                }
	            ];
        	}
        });

        HeaderService.changeState($state.current);
    }])
    .controller('SecProposalsController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilterFactory', '$filter', 'Language', 'SecFactory', 'SecModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'DialogsFactory', 'GlobalDataFactory', 'apiAdmin', '$http', function($scope, $rootScope, HeaderService, $state, TableFilterFactory, $filter, Language, SecFactory, SecModals, AnnexaFormlyFactory, RouteStateFactory, DialogsFactory, GlobalDataFactory, apiAdmin, $http) {
        $scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });

        $scope.alerts = [];
        $scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
         
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.proposals') { 

                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter")
                ]
            }
        });

        HeaderService.changeState($state.current);

        $scope.proposalStates = SecFactory.proposalStates;
        $scope.proposalStatesHeader = angular.copy($scope.proposalStates);
        $scope.proposalStatesHeader.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        $scope.proposalTypeSubtypes = angular.copy(apiAdmin.proposalTypeSubtypes);
        $scope.proposalTypeSubtypes.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            description: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        $scope.filter = [
        	{ id: 'state', type: 'select', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.proposalStates, addAll: true, nameProperty: 'name', callAux: true, preFilter: 'PENDING' },
        	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalSubType', data:$scope.proposalTypeSubtypes , addAll: true, nameProperty: 'description', callAux: true },
            { id: 'proposalNumber', type: 'text', order: 0, label: 'global.sec.literals.proposalNumber' },
            { id: 'extract', type: 'text', order: 7, label: 'global.literals.extract' },
            { id: 'dossier', type: 'text', order: 1, label: 'global.literals.dossier', callAux: true },
            { id: 'organ', type: 'select', order: 3, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: GlobalDataFactory.organs, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
            { id: 'organSessionNumber', type: 'text', order: 6, label: 'global.sec.literals.organSessionNumber', callAux: true },
            { id: 'senderUser', type: 'text', order: 5, label: 'global.sec.literals.senderUser', callAux: true },
            { id: 'sendDate', type: 'dateRange', order: 10, label: 'global.sec.literals.sendDate', callAux: true }
        ];
 
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.filter = routeState.state;
        }
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	if($scope.changeStatusFromHeader){
        		if($scope.filterData.state){
        			filter.state = {id: $scope.filterData.state};
        		}else{
        			filter.state = undefined;
        		}
        		
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'state'");
        		if(filterAux){
        			var status = $linq($scope.proposalStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
        			if(status){
                        filterAux.model = status;
        			}else{
        			    filterAux.model = {};
        			}
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filter.state && filter.state.id != $scope.filterData.state){
        		$scope.filterData.state = filter.state.id;
        	}else if(!filter.state){
        		$scope.filterData.state = undefined;
        	}
        	return filter;
        }

        var organRender = function(data, type, full, meta) {
            var content = '';

            if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
	            var actualOrgan = undefined;
	            
	            if($linq(data).count("x => x.actual == true") == 1) {
	                actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
	            } else {
	                var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
	                if(firstActual && firstActual.id){
	                	var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
	                	actualOrgan = data[indexFirstActual];
	                }
	            }
	
	            if(actualOrgan) {
	                content += actualOrgan.organ[Language.getActiveColumn()];
	            }
            }

            return content;
        }

        var organSessionNumberRender = function(data, type, full, meta) {
            var content = '';
            if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
    	        if(data && data.length > 0) {
	            	 var actualOrgan = undefined;
	
	                 if($linq(data).count("x => x.actual == true") == 1) {
	                     actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
	                 } else {
	                     var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
	                     if(firstActual && firstActual.id){
	                    	 var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
	                     	 actualOrgan = data[indexFirstActual];
	                     }
	                 }
	
	                 if(actualOrgan && actualOrgan.session && actualOrgan.session.sessionNumber){
	                         content += actualOrgan.session.sessionNumber;
	                 }
	            }
            }
            return content;
        }
        
        var organSessionDateRender = function(data, type, full, meta) {
            var content = '';
            if(!(full && full.proposalType && full.proposalType.proposalTypeSubtype && full.proposalType.proposalTypeSubtype == 'DECREE')){
	    	        
	            if(data && data.length > 0) {
	           	 var actualOrgan = undefined;
	
	                if($linq(data).count("x => x.actual == true") == 1) {
	                    actualOrgan = $linq(data).singleOrDefault(undefined, "x => x.actual == true");
	                } else {
	                    var firstActual = $linq(data).firstOrDefault(undefined, "x => x.actual == true");
	                    if(firstActual && firstActual.id){
		                    var indexFirstActual = $linq(data).indexOf("x => x.id == " + firstActual.id);
		                    actualOrgan = data[indexFirstActual];
	                    }
	                }
	
	                if(actualOrgan && actualOrgan.session && actualOrgan.session.sessionDateFirstConvene){
	                     content += $filter('date')(new Date(actualOrgan.session.sessionDateFirstConvene), 'dd/MM/yyyy HH:mm');
	                }
	           }
           }
           return content;
        }
        
        var dossierRender = function(data, type, full, meta) {
            var content = '';
            if(data && data.dossier && data.dossier.dossierNumber) {
            	content = data.dossier.dossierNumber;
            }
            return content;
        }
        
        $scope.headerIconPropStatus = function(icon) {
            if($scope.proposalStates && $scope.proposalStatesHeader.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.proposalStatesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");

                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }

            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var propStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconPropStatus(true)}} {{headerIconPropStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in proposalStatesHeader | orderBy:\'orderStatus\'" ng-click="setProposalTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setProposalTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#proposals-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }
        
        var propStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.proposalStates && $scope.proposalStates.length > 0) {
                var state = $linq($scope.proposalStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }

        var showAproveProposal = function (data, type, full, meta) {
        	if(full.state && _.contains(['PENDING'], full.state)){
        		if(full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
        			return false;   			
        		}else{
        			return true;
        		}
        	}else{
        		return false;
        	}
        }
        
        var showAproveDecree = function (data, type, full, meta) {
        	if(full.state && _.contains(['PENDING'], full.state)){
        		if(full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
        			return true;   			
        		}else{
        			return false;
        		}
        	}else{
        		return false;
        	}
        }
        
        var showAmendmentProposal = function (data, type, full, meta) {
        	if(full.state && _.contains(['PENDING','ACCEPTED'], full.state)){
        		if(full.state == 'ACCEPTED' && full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
        			return false;   			
        		}else{
        			return true;
        		}
        	}else{
        		return false;
        	}
        }
        
        var showCancelProposal = function (data, type, full, meta) {
        	if(full.state && _.contains(['PENDING','ACCEPTED'], full.state)){
        		if(full.state == 'ACCEPTED' && full.proposalType && full.proposalType.proposalTypeSubtype == 'DECREE'){
        			return false;   			
        		}else{
        			return true;
        		}
        	}else{
        		return false;
        	}
        }
        
        var columnsAux = [
        	{ id: 'state', width: '1%', title: propStatusTitle(), render: propStatusRender, sortable: false },
            { id: 'proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') },
            { id: 'extract', column:  new TextColumn($filter, 'global.literals.extract') },
            { id: 'createdTransaction', title: $filter('translate')('global.literals.dossier'), render: dossierRender},
            { id: 'organs', title: $filter('translate')('global.sec.literals.organ'), render: organRender, sortable: false },
            { id: 'organs', title: $filter('translate')('global.sec.literals.organSessionNumber'), render: organSessionNumberRender, sortable: false },
            { id: 'organs', title: $filter('translate')('global.sec.literals.sessionDate'), render: organSessionDateRender, sortable: false },
            { id: 'senderUser', column:  new UserColumn($filter, 'global.sec.literals.senderUser') },
            { id: 'sendDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.proposalSendDate') },
            { id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), 
            		[ 
            			new ActionButton('global.literals.accept','aproveProposal([data])',undefined, undefined, showAproveProposal),
            			new ActionButton('global.literals.accept','aproveDecree([data])',undefined, undefined, showAproveDecree),
            			new ActionButton('global.sec.streamline.amendmentProposal','generateAmendment([data])',undefined, undefined, showAmendmentProposal),
            			new ActionButton('global.literals.cancel','cancelProposal([data])',undefined, undefined, showCancelProposal)
            		]),sortable: false }
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_proposal', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_proposal', columnsAux);
        $scope.tableOrderProperties = {sortName: 'sendDate', sort: [[8,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableProposals',
            origin: 'sec',
            objectType: 'Proposal',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.viewElement = function(id) {
            $state.transitionTo('annexa.sec.proposals.edit', { "proposal" : id});
        };
        
        $scope.aproveProposal = function(id) {
        	SecFactory.updateStateProposal(id, 'ACCEPTED').then(function(data) {
                if(data) {
                	$scope.tableDefinition.reloadInternalData(true, true);
                }
            }).catch(function(error) {
            	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
            })
        };
        
        
        $scope.aproveDecree = function(id) {
        	DialogsFactory.confirm('global.sec.literals.createDecreeMessage', 'global.sec.literals.createDecreeMessageConfirm')
            .then(function (data) {
        		SecFactory.createDocumentModal('SDR', null, undefined, {id:id}, true)
                .then(function(data) {
                    var selfData = data;
                    $http({
                        url: './api/sec/proposal/' + id + '/decreee',
                        method: 'POST', 
                        data: JSOG.encode(selfData.request)
                    }).then(function(data) {
                    	selfData.modal.close();
                    	if(data) {
                        	$scope.tableDefinition.reloadInternalData(true, true);
                        }
                    }).catch(function (error) {
                    	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDecree'));
                    	selfData.modal.close();
                    });
                }).catch(function(error) {
                    //Empty;
                })
           }).catch(function (data) {
	               //Empty
	       });
        };
        
        $scope.generateAmendment = function(id) {
            var modal = angular.copy(SecModals.amendmentProposal);
            $scope.proposalId = id;
            modal.alerts = [];
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};

            var confirmGenerateAmendment = function () {
                if (this.annexaFormly.model.modal_body.reason) {
                	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                    	SecFactory.updateStateProposal($scope.proposalId, 'AMENDMENT', secretariatObservations).then(function(data) {
                            if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function(error) {
                        	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                        })
                    }).catch(function (data) {
                    	//Empty
                    });        	
                    modal.close();
                }
            };

            AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
        };
        
        $scope.cancelProposal = function(id) {
            var modal = angular.copy(SecModals.cancelProposal);
            $scope.proposalId = id;
            modal.alerts = [];
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};

            var confirmCancelProposal = function () {
                if (this.annexaFormly.model.modal_body.reason) {
                	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                	DialogsFactory.confirm('global.sec.literals.cancelPopupProposal', 'global.sec.literals.confirmCancelProposal').then(function (dataAux) {
                    	SecFactory.updateStateProposal($scope.proposalId, 'CANCELED', secretariatObservations).then(function(data) {
                            if(data) {
                            	$scope.tableDefinition.reloadInternalData(true, true);
                            }
                        }).catch(function(error) {
                        	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                        })
                    }).catch(function (data) {
                    	//Empty
                    });        	
                    modal.close();
                }
            };

            AnnexaFormlyFactory.showModal("modalCancelProposal", modal, confirmCancelProposal, false);
        };
        
    }])
    .controller('SecEditProposalController', ['$scope', '$rootScope', 'HeaderService', '$state', 'AnnexaEntityFactory', 'SecFactory', 'DccumentsFactory', function ($scope, $rootScope, HeaderService, $state, AnnexaEntityFactory, SecFactory, DccumentsFactory) {
        $scope.proposal = SecFactory.proposal;
                
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.proposals.edit' && SecFactory.proposal) {
                $rootScope.subHeadButtons = [];

                if(_.contains(['PENDING', 'ACCEPTED'], SecFactory.proposal.state)){
                	if(!(_.contains(['ACCEPTED'], SecFactory.proposal.state) && SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE')){
	                	var diligenceActions = [];
						$rootScope.subHeadButtons.push(new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.literals.actions', undefined, undefined, undefined, undefined, undefined, diligenceActions, 'dropdown-menu primary pull-right'));
			                
						var aproveProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.accept', undefined, 'aproveProposal');
						var generateAmendment = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.sec.streamline.amendmentProposal', undefined, 'generateAmendment');
						var cancelProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.cancel', undefined, 'cancelProposal');
					
						if(_.contains(['PENDING'], SecFactory.proposal.state)) {
							diligenceActions.push(aproveProposal);
							diligenceActions.push(generateAmendment);
							diligenceActions.push(cancelProposal);
						   	if(SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE' && SecFactory.proposal.decreeDocument){
						   		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.sec.literals.editAgrementDocument', undefined, 'editDecreeDocument'));
						   	}
						}else if(_.contains(['ACCEPTED'], SecFactory.proposal.state)){
							if(!(SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE' && SecFactory.proposal.decreeDocument)){
							   	diligenceActions.push(generateAmendment);
							   	diligenceActions.push(cancelProposal);
							}
					    }
                	}
				}
            }
        });

        HeaderService.changeState($state.current, true);
        
    }])
    .controller('SecSessionsController',['$scope', '$rootScope', 'HeaderService', '$state', 'SecFactory', 'Language', 'TableFilterFactory', '$filter', 'AnnexaFormlyFactory', 'SecModals', 'RestService', 'RouteStateFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, SecFactory, Language, TableFilterFactory, $filter, AnnexaFormlyFactory, SecModals, RestService, RouteStateFactory, GlobalDataFactory) {
        $scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });

        $scope.alerts = [];
        
        $scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.sessions') {
                $rootScope.subHeadButtons = [
                	new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#sec-principal', 'fa fa-plus', 'global.sec.literals.newSession', undefined, 'newSession').setPermissions('view_sessions')
                ]
            }
        });

        HeaderService.changeState($state.current);

        $scope.newSession = function() {
            var modal = SecModals.sessionNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.row1 = {};
            modal.annexaFormly.model.row2 = { sessionFirstDate: new Date(), sessionFirstTime: new Date() };
            var dt = new Date();
            dt.setMinutes( dt.getMinutes() + 30 );
            modal.annexaFormly.model.row3 = { sessionSecondDate: dt, sessionSecondTime: dt }
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNesSession', modal, $scope.saveSession, false);
        };

        $scope.saveSession = function (session) {
            var modal = this;

            var meetingLocation = undefined;
            var attendees = [];


            var model = {
                state: 'PENDING',
                organ: session.annexaFormly.model.row1.organ ? { id: session.annexaFormly.model.row1.organ } : null,
                sessionType: session.annexaFormly.model.row1.sessionType ? { id: session.annexaFormly.model.row1.sessionType } : null,
                meetingLocation: ((session.annexaFormly.model.row4.meetingLocation)?((session.annexaFormly.model.row4.meetingLocation.id)?{id:session.annexaFormly.model.row4.meetingLocation.id}:{id:session.annexaFormly.model.row4.meetingLocation}): null),
                sessionDateFirstConvene: session.annexaFormly.model.row2.sessionFirstDate && session.annexaFormly.model.row2.sessionFirstTime ?
                    new Date(Date.UTC(
                        session.annexaFormly.model.row2.sessionFirstDate.getFullYear(),
                        session.annexaFormly.model.row2.sessionFirstDate.getMonth(),
                        session.annexaFormly.model.row2.sessionFirstDate.getDate(),
                        session.annexaFormly.model.row2.sessionFirstTime.getUTCHours(),
                        session.annexaFormly.model.row2.sessionFirstTime.getUTCMinutes())) : null,
                sessionDateSecondConvene: session.annexaFormly.model.row3.sessionSecondDate && session.annexaFormly.model.row3.sessionSecondTime ?
                        new Date(Date.UTC(
                            session.annexaFormly.model.row3.sessionSecondDate.getFullYear(),
                            session.annexaFormly.model.row3.sessionSecondDate.getMonth(),
                            session.annexaFormly.model.row3.sessionSecondDate.getDate(),
                            session.annexaFormly.model.row3.sessionSecondTime.getUTCHours(),
                            session.annexaFormly.model.row3.sessionSecondTime.getUTCMinutes())) : null
            }
            if(model.sessionDateFirstConvene && model.sessionDateSecondConvene && model.sessionDateFirstConvene > model.sessionDateSecondConvene){
            	modal.alerts.push({ msg: $filter('translate')('global.sec.literals.firstSessionAfterSecondSession') });
            }else{
            	RestService.insert('./api/sec/session', model)
            	.then(function() {
            		$scope.tableDefinition.reloadInternalData(false, true);
            		modal.close();
            	}).catch(function(error) {
            		modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
            	});
            }
        }

        $scope.sessionStates = SecFactory.sessionStates;
        $scope.sessionStatesHeader = angular.copy($scope.sessionStates);
        $scope.sessionStatesHeader.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        var getOrganWithPermissions =  $linq(GlobalDataFactory.organs).where(function(x){
        	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
        		var intersectedProfiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
        		if(intersectedProfiles && intersectedProfiles.length > 0){
        			return true;
        		}else{
        			return false;
        		}
        	}else{
        		return false;
        	}
        }).toArray();
        
        $scope.filter = [
        	{ id: 'state', type: 'select-multiple', order: 3, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.sessionStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:[{ id: 'PENDING' }, { id: 'CONVENED' }, { id: 'STARTED' }, { id: 'EXECUTED' }] },
            { id: 'organ', type: 'select', order: 0, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: getOrganWithPermissions, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
            { id: 'sessionDateFirstConvene', type: 'dateRange', order: 10, label: 'global.sec.literals.sessionDate', callAux: true },
            { id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
            { id: 'sessionNumber', type: 'text', order: 1, label: 'global.sec.literals.sessionNumber' }
        ];
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.filter = routeState.state;
        }

        var getFilterCall = function () {
            return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	if($scope.changeStatusFromHeader){
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'state'");
        		if($scope.filterData.state){
        			if(filterAux){
            			var status = $linq($scope.sessionStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
            			filter.state = [status];
           				filterAux.model = filter.state;        		
            		}else{
            			filter.state = undefined;
           		    	filterAux.model = {};
            		}
            	}else{
        			filter.state = undefined;
        		    if(filterAux){
        		    	filterAux.model = {};
        		    }
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filter.state && filter.state.length == 1){
        		if(filter.state[0].id != $scope.filterData.state){
        			$scope.filterData.state = filter.state[0].id;
        		}
        		
        	}else if(!filter.state || filter.state.length != 1){
        		$scope.filterData.state = undefined;
        	}
        	return filter;
        }


        $scope.headerIconSessionStatus = function(icon) {
            if($scope.sessionStates && $scope.sessionStatesHeader.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.sessionStatesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }

            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var sessionStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconSessionStatus(true)}} {{headerIconSessionStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in sessionStatesHeader | orderBy:\'orderStatus\'" ng-click="setSessionTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setSessionTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#sessions-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }
        
        var sessionStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.sessionStates && $scope.sessionStates.length > 0) {
                var state = $linq($scope.sessionStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }


        var columnsAux = [
            { id: 'createdDate', visible: false, title:'NotShow' },
            { id: 'state', width: '5%', title: sessionStatusTitle(), render: sessionStatusRender, sortable: false},
            { id: 'organ', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.organ', Language.getActiveColumn()) },
            { id: 'sessionType', width: '15%', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.type', Language.getActiveColumn()) },
            { id: 'sessionDateFirstConvene', width: '10%', column: new DateTimeColumn($filter, 'global.sec.literals.sessionDate')},
            { id: 'sessionNumber', width: '10%', column: new TextColumn($filter, 'global.sec.literals.sessionNumber')},
            { id: 'id', width: '120px', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), []), sortable: false }
        ];
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_session', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_session', columnsAux);
        $scope.tableOrderProperties = {sortName: 'sessionDateFirstConvene', sort: [[4,'desc']]};
        $scope.tableDefinition = {
            id: 'tableSessions',
            origin: 'sec',
            objectType: 'Session',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.viewElement = function(id) {
            $state.transitionTo('annexa.sec.sessions.view', { "session" : id });
        };
    }])
    .controller('SecViewSessionController',['$scope', '$rootScope', 'SecFactory', 'HeaderService', '$state', function ($scope, $rootScope, SecFactory, HeaderService, $state) {
        $scope.session = SecFactory.session;

        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.sessions.view' && SecFactory.session) {
            	$rootScope.headButtons = [];
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton ', undefined, '#edit-session','fa-plus', 'global.sec.literals.expEni', undefined, 'createEni'));
            	$rootScope.subHeadButtons = [];
            }
        });

        HeaderService.changeState($state.current, true);
    }])
    .controller('SecDecreesController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilterFactory', '$filter', 'Language', 'SecFactory', 'SecModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'DialogsFactory', 'apiAdmin', 'GlobalDataFactory', function($scope, $rootScope, HeaderService, $state, TableFilterFactory, $filter, Language, SecFactory, SecModals, AnnexaFormlyFactory, RouteStateFactory, DialogsFactory, apiAdmin, GlobalDataFactory) {
        $scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });

        $scope.decreeStates = angular.copy(SecFactory.decreeStates);
        $scope.decreeStates.unshift({id: '', icon: 'minus', style: 'text-black', name: $filter('translate')('global.literals.all'), orderStatus: 0});
        
        $scope.alerts = [];
        $scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
         
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.decrees') { 
                $rootScope.subHeadButtons = [new HeadButtonSearch("#tableFilter")]
            }
        });

        HeaderService.changeState($state.current);

        $scope.proposalTypes = angular.copy(GlobalDataFactory.proposalTypes);
        $scope.proposalTypes = $linq($scope.proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
        $scope.proposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
        
        $scope.filter = [
        	{ id: 'state', type: 'select', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.decreeStates, addAll: true, nameProperty: 'name', callAux: true},
        	{ id: 'decreeNumber', type: 'text', order: 0, label: 'global.sec.literals.decreeNumberAbrv'},
        	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalType', data:$scope.proposalTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
            { id: 'proposalNumber', type: 'text', order: 1, label: 'global.sec.literals.proposalNumber', callAux: true },
            { id: 'decreeDate', type: 'dateRange', order: 2, label: 'global.sec.literals.decreeDate', callAux: true },
            { id: 'extract', type: 'text', order: 3, label: 'global.literals.extract' }
        ];
 
        var routeState = RouteStateFactory.getRouteState($state.current);
        if(routeState) {
            $scope.filter = routeState.state;
        }
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	return  TableFilterFactory.getFilterCallAux($scope.filter, true);
        }
        
        var decStatusRender = function(data, type, full, meta) {
            var content = '';
            if($scope.decreeStates && $scope.decreeStates.length > 0) {
                var state = $linq($scope.decreeStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");
                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }
            return content;
        }
        
        var columnsAux = [
        	{ id: 'status', width: '1%', title: '', render: decStatusRender, sortable: false },
            { id: 'proposal.proposalType', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.proposalType', Language.getActiveColumn()) },
        	{ id: 'decreeNumber', column: new TextColumn($filter, 'global.sec.literals.decreeNumberAbrv') },
        	{ id: 'decreeDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.decreeDate') },
        	{ id: 'extract', column:  new TextColumn($filter, 'global.literals.extract') },
            { id: 'proposal.proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') },
            { id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'),undefined),sortable: false }
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_decree', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_decree', columnsAux);
        $scope.tableOrderProperties = {sortName: 'decreeDate', sort: [[2,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableDecrees',
            origin: 'sec',
            objectType: 'Decree',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope
        };

        $scope.viewElement = function(id) {
            $state.transitionTo('annexa.sec.decrees.edit', { "decrees" : id});
        };
        
    }])
    .controller('SecEditDecreeController', ['$scope', '$rootScope', 'HeaderService', '$state', 'AnnexaEntityFactory', 'SecFactory', 'DccumentsFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, AnnexaEntityFactory, SecFactory, DccumentsFactory, GlobalDataFactory) {
        $scope.decree = SecFactory.decree;
                
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.decrees.edit' && SecFactory.decree) {
            	$rootScope.subHeadButtons = [];
            	var canSendToSign = false;
            	var canEditDocument = false;
                var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
	        	if($scope.decree && $scope.decree.documents && documentType){
	        		if($scope.decree.status && _.contains(['PENDING'], $scope.decree.status)){
		        		_.forEach($scope.decree.documents, function(document){
		        			if(document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
		        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED', 'COMPLETE'], document.document.docStatus)){
		        					canSendToSign = true;
		        				}
		        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED'], document.document.docStatus)){
		        					canEditDocument = true;
		        				}
		        			}
		        		});
	        		}
	        	}
	        	if(canSendToSign){
	        		var diligenceActions = [];
	 				$rootScope.subHeadButtons.push(new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.literals.actions', undefined, undefined, undefined, undefined, undefined, diligenceActions, 'dropdown-menu primary pull-right'));
	 				diligenceActions.push(new HeadButton('btn primary breadDivButton', undefined, '#edit-decree', undefined, 'global.sec.streamline.amendmentProposal', undefined, 'generateAmendment'));
	 				diligenceActions.push(new HeadButton('btn primary breadDivButton', undefined, '#edit-decree', undefined, 'global.literals.cancel', undefined, 'cancelProposal'));
	             	
	        		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-decree', undefined, 'global.literals.sendToSignValidate', undefined, 'sendToSign'));
				}
                if(canEditDocument){
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-decree', undefined, 'global.sec.literals.editAgrementDocument', undefined, 'editDecreeDocument'));
                }
            }
        });

        HeaderService.changeState($state.current, true);
        
    }])
;